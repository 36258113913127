import http from "../http/http"

// 品牌基础列表
export const brandPageList = (params) => {
    return http({
        url: "/websiteBrand/queryWebBrandPage",
        method: "post",
        data: params
    })
}

// 品牌基础详情
export const brandItemInfo = (params) => {
    return http({
        url: "/websiteBrand/queryWebBrandInfo",
        method: "post",
        data: params
    })
}

// 品牌入驻申请保存
export const brandApplySave = (params) => {
    return http({
        url: "/websiteBrand/residenceApply",
        method: "post",
        data: params
    })
}