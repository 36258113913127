<template>
  <div class="title-adorn">
    <img :src="getImgUrl()" class="img" :style="'width:' + width + 'px'" />
    <div :style="'padding:0 '+space+'px'">
      <slot></slot>
    </div>
    <img :src="getImgUrl()" class="img reverse" :style="'width:' + width + 'px'" />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: '13'
    },
    isReverse: {
      type: Boolean,
      default: false
    },
    type: {
      type: [String, Number],
      default: 1
    },
    space: {
      type: [String, Number],
      default: 5
    }
  },
  created() {},
  methods: {
    getImgUrl() {
      switch (this.type) {
        case 2:
          return require('@/static/img/titleAdorn/icon2.png')
        default:
          return require('@/static/img/titleAdorn/icon1.png')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-adorn {
  display: flex;
  align-items: center;
  .img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.reverse {
  transform: rotateY(180deg);
}
</style>
