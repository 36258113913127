<template>
  <div class="enroll">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-d-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/brandDynamic' }">{{$t('brandNews')}}</el-breadcrumb-item>
        <el-breadcrumb-item>报名入口</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div><img src="../../static/img/enroll/bg.png" alt="" class="top-img"></div>
    <div class="enroll-notice">
      <div class="title">
        <c-title :width="16">
          <div class="text">关于征集中国农产品地理标志品牌形象馆第二批入馆品牌的通知</div>
        </c-title>
      </div>
      <div class="content">
        <div style="margin:0 0 20px 0">各有关品牌方：</div>
        <div class="p-text">中国地大物博，资源丰富，迄今，已产生成千上万的地理标志农产品，并有“10+275”个地理标志产品，入选中欧地标互认协定，链接欧洲乃至全球各国。</div>
        <div class="p-text">中国农产品地理标志品牌形象馆（以下简称地标品牌馆），是面向国内、国际地理标志农产品爱好者，通过文字与图像，在云上持续性展示中国地理标志农产品的品牌产品设计、品牌产品形象的平台。自4月23日，地标品牌馆在“2020迪拜世博会中华文化馆”上线全球发布以来，广受各界关注。</div>
        <div class="p-text">目前，地标品牌馆已收录了长白山人参、烟台苹果、盐池滩羊肉、安吉白茶等47个品牌。为更多、更好地传播与推广中国农产品地理标志品牌，向世界传达中国特色、优质地理标志农产品的情况，第二期入馆品牌征集活动将于5月18日开启，本次开放30个席位，申报截止日期为6月18日。</div>
        <div class="p-text">咨询电话：裘老师 0571-85128029  17805805952</div>
        <div class="creat" style="margin:20px 0 0 0">中国农产品地理标志品牌形象馆</div>
        <div class="creat">2022年5月18日</div>
      </div>
    </div>
    <div class="form-box" v-if="formShow">
      <div class="form-content">
        <div class="form-title">2022中国农产品地理标志品牌馆第二批品牌入驻意向表</div>
        <el-form ref="form" :model="form" :rules="rules" :show-message="false">
          <div class="form border">
            <div class="flex-row">
              <div class="flex-left b-r">
                <div class="flex-row b-b">
                  <div class="title b-r t-78">*品牌名称</div>
                  <div class="input">
                    <el-form-item prop="brandName">
                      <el-input v-model="form.brandName"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="flex-row b-b">
                  <div class="title b-r t-78">*申报单位</div>
                  <div class="input">
                    <el-form-item prop="applyUnit">
                      <el-input v-model="form.applyUnit"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="flex-right b-b flex-row">
                <div class="title b-r t-78">*地标类型</div>
                <el-form-item prop="landmarkType" style="flex:auto;">
                  <el-checkbox-group v-model="form.landmarkType" class="check-box">
                    <el-checkbox label="1">地理标志产品（GI）</el-checkbox>
                    <el-checkbox label="2">农产品地理标志（AGI）</el-checkbox>
                    <el-checkbox label="3">证明/集体（地理标志）商标</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
            </div>
            <div class="flex-row">
              <div class="flex-left b-r b-b flex-row">
                <div class="title b-r t-78">*联系人</div>
                <div class="input">
                  <el-form-item prop="contacts">
                    <el-input v-model="form.contacts"></el-input>
                  </el-form-item>
                </div>
                <div class="title b-r b-l t-44">职务</div>
                <div class="input">
                  <el-form-item prop="duty">
                    <el-input v-model="form.duty"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="flex-right b-b flex-row">
                <div class="flex-row b-r" style="width:165px;">
                  <div class="title b-r t-44">*手机</div>
                  <div class="input">
                    <el-form-item prop="mobilePhone">
                      <el-input v-model="form.mobilePhone" maxlength="11"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="title b-r t-44">邮箱</div>
                <div class="input">
                  <el-form-item prop="eMail">
                    <el-input v-model="form.eMail"></el-input>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="flex-row" style="flex:auto;">
              <div class="flex-left b-r flex-row">
                <div class="title b-r t-78 title-wrap">
                  <div>*品牌简介</div>
                  <div class="label">(1000字左右)</div>
                </div>
                <div class="input">
                  <el-form-item prop="brandIntroduce">
                    <el-input v-model="form.brandIntroduce" type="textarea" :autosize="{minRows: 7, maxRows: 7}" resize="none" style="background: transparent;"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="flex-right flex-row" style="flex-direction: column;">
                <div class="flex-row b-b" style="height:50%;">
                  <div class="title b-r w-165 title-wrap">
                    <div>*形象资料</div>
                    <div class="label">(品牌LOGO像素要求:900*900以下)</div>
                  </div>
                  <div class="up-logo">
                    <el-form-item prop="logoUrl">
                      <el-upload class="avatar-uploader" :action="upImgUrl" accept="image/*" :show-file-list="false" name="clientFile" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="form.logoUrl" :src="form.logoUrl" class="avatar">
                        <div class="icon" v-else>LOGO</div>
                      </el-upload>
                    </el-form-item>
                  </div>
                </div>
                <div class="flex-row" style="height:50%;">
                  <div class="title b-r w-165 title-wrap">
                    <div>其他辅助性</div>
                    <div>介绍资料</div>
                  </div>
                  <div class="auxiliary-data">（品牌主形象、包装、海报、品牌形象片等，可单独上传附件。要求pdf、word、mp4等格式。发送至邮箱cagibrand@163.com）</div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <div class="tip">（*内容为必填）</div>
        <div class="btn-box">
          <div class="btn" @click="submitForm()">提交</div>
          <div class="btn" @click="resetForm()">重置</div>
        </div>
        <div class="success-box" v-if="subSuccess">
          <div class="success">
            <img src="../../static/img/enroll/success.png" class="success-icon" />
            <img src="../../static/img/enroll/close.png" class="close" @click="subSuccess = false">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cTitle from '@/components/cTitle/cTitle.vue'
import { brandApplySave } from '../../api/brand'
export default {
  components: {
    cTitle
  },
  data() {
    return {
      form: {
        brandName: '',
        applyUnit: '',
        landmarkType: [],
        contacts: '',
        duty: '',
        mobilePhone: '',
        eMail: '',
        brandIntroduce: '',
        logoUrl: ''
      },
      rules: {
        brandName: [
          {
            required: true,
            message: '请输入品牌名称',
            trigger: ['blur', 'change']
          }
        ],
        applyUnit: [
          {
            required: true,
            message: '请输入申报单位',
            trigger: ['blur', 'change']
          }
        ],
        landmarkType: [
          {
            required: true,
            message: '请选择地标类型',
            trigger: ['blur', 'change']
          }
        ],
        contacts: [
          {
            required: true,
            message: '请输入联系人',
            trigger: ['blur', 'change']
          }
        ],
        // duty: [
        //   {
        //     required: true,
        //     message: '请输入职务',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        mobilePhone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur'
          }
        ],
        // eMail: [
        //   {
        //     required: true,
        //     message: '请输入电子邮箱',
        //     trigger: ['blur', 'change']
        //   },
        //   {
        //     type: 'email',
        //     message: '请输入正确的邮箱地址',
        //     trigger: ['blur', 'change']
        //   }
        // ],
        brandIntroduce: [
          {
            required: true,
            message: '请输入品牌简介',
            trigger: ['blur', 'change']
          }
        ],
        logoUrl: [
          {
            required: true,
            message: '请上传logo图',
            trigger: ['blur', 'change']
          }
        ]
      },
      upImgUrl: `${process.env.VUE_APP_URL}/commonController/getFileUrlYszsg`,
      subSuccess: false,
      formShow: false
    }
  },
  created() {
    this.formShow = this.checkTime('2022/05/18', '2022/06/18')
  },
  methods: {
    // 报名时间区间
    checkTime(startTime, endTime) {
      var curDate = new Date()
      startTime = new Date(startTime)
      endTime = new Date(endTime)
      if (curDate >= startTime && curDate <= endTime) {
        return true
      }
      return false
    },
    // 校验
    submitForm() {
      this.$refs['form'].validate((valid, err) => {
        if (valid) {
          this.save()
        } else {
          this.$notify({
            type: 'warning',
            message: '请正确填写信息',
            position: 'bottom-right'
          })
          return false
        }
      })
    },
    // 保存信息
    save() {
      let params = this.form
      params.landmarkType = params.landmarkType.toString()
      brandApplySave(params).then((res) => {
        if (res.code === 10000) {
          this.subSuccess = true
          this.resetForm()
        }
      })
    },
    // 重置表单
    resetForm() {
      this.$refs['form'].resetFields()
    },
    // 图片上传成功
    handleAvatarSuccess(res, file) {
      this.form.logoUrl = res.data
    },
    // 图片上传前校验
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 3
      const isSize = new Promise(function (resolve, reject) {
        let width = 900
        let height = 900
        let _URL = window.URL || window.webkitURL
        let img = new Image()
        img.onload = function () {
          let valid = img.width <= width && img.height <= height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(
        () => {
          return file
        },
        () => {
          this.$message({
            message: '请上传尺寸为900*900的图片!',
            type: 'error'
          })
          return Promise.reject()
          return false //必须加上return false; 才能阻止
        }
      )
      if (!isSize) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M && isSize
    }
  }
}
</script>
<style lang="scss">
.enroll {
  flex: 1;
  display: flex;
  flex-direction: column;
  .breadcrumb {
    width: 1140px;
    margin: 0 auto;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .top-img {
    width: 100%;
    height: auto;
  }
  .enroll-notice {
    width: 1140px;
    min-height: 500px;
    margin: 0 auto;
    background: url('../../static/img/about/bg.png') no-repeat center;
    background-size: cover;
    // padding: 0 0 30px 0;
    .title {
      font-size: 28px;
      line-height: 28px;
      text-align: center;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px 0 48px 0;
    }
    .content {
      padding: 0 100px 60px;
      color: #fff;
      font-size: 16px;
      line-height: 1.75;
      text-align: justify;
      .p-text {
        text-indent: 32px;
      }
      .creat {
        text-align: right;
      }
    }
  }
  .form-box {
    width: 1140px;
    height: 700px;
    margin: 0 auto;
    background: url('../../static/img/enroll/form_bg.png') no-repeat center;
    background-size: 100% 100%;
    .el-form-item {
      width: 100%;
      flex: auto;
    }
    .el-checkbox {
      margin: 0 5px 0 0;
    }
    .el-checkbox__inner {
      border: 1px solid #ad1b20;
      background: transparent;
    }
    // .el-radio__input.is-checked {
    //   background: #ad1b20;
    // }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #ad1b20 !important;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background: #ad1b20 !important;
      border-color: #ad1b20 !important;
    }
    .el-input__inner {
      background: transparent;
      width: 100%;
      border: none;
    }
    .el-textarea__inner {
      background: transparent;
      border: none;
    }
    .el-textarea .el-input__count {
      background: transparent;
    }
    .el-form-item {
      margin: 0;
    }
    .el-form-item__content {
      height: 100%;
    }
    .form-content {
      width: 726px;
      height: 444px;
      margin: 120px auto 0;
      position: relative;
      .form-title {
        font-size: 18px;
        line-height: 18px;
        color: #333333;
        font-weight: bold;
        text-align: center;
        padding: 30px 0 18px 0;
      }
      .border {
        border: 1px solid #d79886;
      }
      .b-t {
        border-top: 1px solid #d79886;
      }
      .b-r {
        border-right: 1px solid #d79886;
      }
      .b-b {
        border-bottom: 1px solid #d79886;
      }
      .b-l {
        border-left: 1px solid #d79886;
      }
      .form {
        width: 726px;
        height: 320px;
        color: #333333;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        flex-direction: column;
        .flex-row {
          display: flex;
          min-height: 46px;
        }
        .input {
          flex: auto;
          display: flex;
          align-items: center;
        }
        .flex-left {
          flex: auto;
        }
        .flex-right {
          width: 58%;
          flex: 0 0 auto;
        }
        .title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 0 0 auto;
        }
        .required {
          position: relative;
          &::before {
            content: '*';
            color: #f56c6c;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .title-wrap {
          flex-direction: column;
          .label {
            font-size: 14px;
            margin: 10px 0 0 0;
            text-align: center;
          }
        }
        .t-78 {
          width: 78px;
        }
        .t-44 {
          width: 44px;
        }
        .w-165 {
          width: 165px;
        }
        .check-box {
          padding: 0 0px 0 10px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          height: 100%;
        }
        .up-logo {
          flex: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          .avatar-uploader {
            width: 118px;
            height: 70px;
            border: 1px dashed #bb4341;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            .avatar {
              width: 118px;
              height: 70px;
            }
            .icon {
              width: 118px;
              height: 70px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #333333;
            }
          }
        }
        .auxiliary-data {
          font-size: 14px;
          padding: 0 22px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .tip {
    font-size: 14px;
    text-align: right;
  }
  .btn-box {
    display: flex;
    justify-content: center;
    .btn {
      cursor: pointer;
      font-size: 14px;
      color: #fff;
      width: 60px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      background: url('../../static/img/enroll/btn.png') no-repeat center;
      background-size: 100% 100%;
      margin: 0 90px;
    }
  }

  .success-box {
    width: 726px;
    height: 444px;
    position: absolute;
    top: 0;
    left: 50%;
    // background: rgba($color: #000000, $alpha: 0.3);
    z-index: 9;
    margin: 0 0 0 -362px;
    display: flex;
    align-items: center;
    justify-content: center;
    .success {
      position: relative;
      .success-icon {
        width: 410px;
        height: 220px;
      }
      .close {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 28px;
        top: 28px;
        cursor: pointer;
      }
    }
  }
}
</style>